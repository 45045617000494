import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject, combineLatest, map, Observable, startWith, Subject, tap } from 'rxjs';
import { CartItem } from './cart-item';
import { Price } from './price';
import { LibraryService } from '../library/library.service';
import { Cookie } from '../cookie';
import { Router } from '@angular/router';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';

@Injectable({
    providedIn: 'root'
})
export class ShoppingCartService {
    private readonly cartSource: BehaviorSubject<CartItem[]>;
    private readonly visibilityChanges: BehaviorSubject<boolean>;
    private readonly itemsAddedSource: Subject<CartItem>;
    private readonly itemsRemovedSource: Subject<CartItem>;
    private readonly checkoutStartedSource: Subject<void>;
    private readonly keyName = `${Cookie.prefix}cart`;
    private readonly isBrowser = isPlatformBrowser(this.platformId);
    private readonly isServer = isPlatformServer(this.platformId);

    constructor(
        private libraryService: LibraryService,
        @Inject(PLATFORM_ID) private platformId: string,
        private router: Router
    ) {
        this.cartSource = new BehaviorSubject<CartItem[]>([]);
        this.items = this.cartSource.asObservable();

        this.visibilityChanges = new BehaviorSubject<boolean>(false);
        this.visibility = this.visibilityChanges.asObservable();

        this.itemsAddedSource = new Subject<CartItem>();
        this.itemsRemovedSource = new Subject<CartItem>();
        this.checkoutStartedSource = new Subject<void>();
    }

    public visibility: Observable<boolean>;
    public items: Observable<CartItem[]>;
    public totalPriceSnapshot: Price;

    public get itemsAdded(): Observable<CartItem> {
        return this.itemsAddedSource.asObservable();
    }

    public get checkoutStarted(): Observable<void> {
        return this.checkoutStartedSource.asObservable();
    }

    public get itemsRemoved(): Observable<CartItem> {
        return this.itemsRemovedSource.asObservable();
    }

    public get itemsSnapshot(): CartItem[] {
        return this.cartSource.value;
    }

    public get productCount(): number {
        return this.itemsSnapshot.map(x => x.quantity).reduce((x, y) => x + y, 0);
    }

    public get totalPrice(): Observable<Price> {
        return combineLatest([this.items, this.libraryService.user]).pipe(
            map(x => {
                const items = x[0];
                const user = x[1];
                return items.filter(y => !user.Inventory.map(x => x.ItemId).includes(y.id));
            }),
            map(x =>
                x
                    .map(x => ({
                        quantity: x.quantity,
                        amount: x.price.amount,
                        originalAmount: x.price.originalAmount,
                        currencyCode: x.price.currencyCode
                    }))
                    .reduce(
                        (x, y) => ({
                            amount: x.amount + y.amount * y.quantity,
                            originalAmount: x.originalAmount + y.originalAmount * y.quantity,
                            currencyCode: y.currencyCode
                        }),
                        {
                            amount: 0,
                            originalAmount: 0,
                            currencyCode: 'EUR'
                        }
                    )
            ),
            tap(x => (this.totalPriceSnapshot = x))
        );
    }

    public addItem(item: CartItem) {
        const foundItem = this.itemsSnapshot.find(x => x.id === item.id);
        if (foundItem) {
            // We will not support duplicate items
            return;
        } else {
            item.owned = this.libraryService.user.pipe(
                map(y => y.Inventory.map(w => w.ItemId).includes(item.id)),
                startWith(false)
            );

            this.itemsSnapshot.push(item);
            this.itemsAddedSource.next(item);
        }

        this.updateCart();
    }

    public restore() {
        var payload = window.localStorage.getItem(this.keyName);
        if (!payload) {
            return;
        }

        const cart = JSON.parse(payload) as {
            items: CartItem[];
        };

        for (const item of cart.items) {
            this.addItem(item);
        }
    }

    public clear() {
        for (const item of this.itemsSnapshot) {
            this.removeItem(item.id);
        }
    }

    public removeItem(id: string, quantity = -1) {
        const foundItem = this.itemsSnapshot.find(x => x.id === id);
        if (!foundItem) {
            return;
        }

        foundItem.quantity -= quantity;
        if (foundItem.quantity === 0 || quantity === -1) {
            const index = this.itemsSnapshot.findIndex(x => x.id === id);
            const items = this.itemsSnapshot.splice(index, 1);
            this.itemsRemovedSource.next(items[0]);
        }

        this.updateCart();
    }

    public changeQuantity(id: string, quantity: number) {
        const foundItem = this.itemsSnapshot.find(x => x.id === id);
        if (!foundItem) {
            throw new Error(`Variant not found with id ${id}`);
        }

        foundItem.quantity = quantity;
        this.updateCart();
    }

    public checkout() {
        this.changeVisibility(false);
        this.checkoutStartedSource.next();
        this.router.navigate(['/checkout']);
    }

    private updateCart() {
        this.cartSource.next(this.cartSource.value);
        if (this.isBrowser) {
            this.store();
        }
    }

    public changeVisibility(value: boolean) {
        this.visibilityChanges.next(value);
    }

    private store() {
        const payload = {
            items: this.itemsSnapshot.map(x => ({
                id: x.id,
                name: x.name,
                link: x.link,
                coverUrl: x.coverUrl,
                price: x.price,
                quantity: x.quantity
            }))
        };
        const stringValue = JSON.stringify(payload);
        window.localStorage.setItem(this.keyName, stringValue);
    }
}
